import React from 'react';
import {
    FC,
    useContext,
    useEffect,
    useReducer,
    createContext,
    useCallback
} from "react"
import SessionContext from "../entities/SessionContext"
import OutgoingItem from '../entities/OutgoingItem';
import DocumentItem from '../entities/DocumentItem';
import OutgoingReportSaveRequest from '../entities/OutgoingReportSaveRequest';
import AntwortenItem from '../entities/AntwortenItem';
import LoadingAnimation from '../helper/loading.component';
import ReactLoading from 'react-loading';
import { getOutgoingExchangeReport, uploadOutgoingExchangeReport } from '../DataAccess';
import RadioButton from './inputRadio'
import { Button } from '@progress/kendo-react-buttons';
import { ValidationDialog } from './dialogs/validation-dialog';
import $ from 'jquery';
import Evaluation from './Evaluation';

interface State {
    outgoingItem: OutgoingItem
    TempReportFilename: string
    TempReportFiletype: string
    submitted: boolean
    submittedJustNow: boolean
    submitDialogVisible: boolean
    validationErrors: string[]
    validationDialogVisible: boolean
    applicationDeadline: string
    isSaving: boolean
    ready: boolean
    authorized: boolean
}

type Action =
    {
        type: "initial"
        payload: OutgoingItem
    }
    | {
        type: "setAllowPublicationReport"
        payload: boolean
    }
    | {
        type: "setReport"
        payload: DocumentItem
    }
    | {
        type: "setTempReportFilename"
        payload: string
    }
    | {
        type: "setTempReportFiletype"
        payload: string
    }
    | {
        type: "setValidationErrors"
        payload: string[]
    }
    | {
        type: "setValidationDialogVisible"
        payload: boolean
    }
    | {
        type: "setApplicationDeadline"
        payload: string
    }
    | {
        type: "setIsSaving"
        payload: boolean
    }
    | {
        type: "setReady"
        payload: boolean
    }
    | {
        type: "setAuthorized"
        payload: boolean
    }
    | {
        type: "setSubmitted"
        payload: boolean
    }
    | {
        type: "setSubmittedJustNow"
        payload: boolean
    }
    | {
        type: "setAntwortFrage"
        payload: {
            ThemenID: number | null
            FragenID: number | null
            Auswahl: number | null
            Art: number
            Pflicht: boolean
        }
    }
    | {
        type: "setAntwort"
        payload: {
            ThemenID: number | null
            FragenID: number | null
            Auswahl: number | null
            Art: number
            Pflicht: boolean
        }
    }
    | {
        type: "setAntwortKommentar"
        payload: {
            ThemenID: number | null
            Kommentar: string
            Art: number
            Pflicht: boolean
        }
    }

const outgoingReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "setSubmittedJustNow":
            return {
                ...state,
                submittedJustNow: action.payload
            }
        case "setSubmitted":
            return {
                ...state,
                submitted: action.payload
            }
        case "setAuthorized":
            return {
                ...state,
                authorized: action.payload
            }
        case "setReady":
            return {
                ...state,
                ready: action.payload
            }
        case "setApplicationDeadline":
            return {
                ...state,
                applicationDeadline: action.payload
            }
        case "setIsSaving":
            return {
                ...state,
                isSaving: action.payload
            }
        case "setValidationDialogVisible":
            return {
                ...state,
                validationDialogVisible: action.payload
            }
        case "setValidationErrors":
            return {
                ...state,
                validationErrors: action.payload
            }
        case "setTempReportFilename":
            return {
                ...state,
                TempReportFilename: action.payload
            }
        case "setTempReportFiletype":
            return {
                ...state,
                TempReportFiletype: action.payload
            }
        case "initial":
            return {
                ...state,
                outgoingItem: action.payload
            }
        case "setAllowPublicationReport":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, allowPublicationReport: action.payload }
            }
        case "setReport":
            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    Report: {
                        ...state.outgoingItem.Report,
                        FileName: action.payload.FileName,
                        FileType: action.payload.FileType,
                        Content: action.payload.Content
                    }
                }
            }
        case "setAntwortFrage":
            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    Antworten:
                        [...state.outgoingItem.Antworten.filter(f => f.FragenID !== action.payload.FragenID),
                        {
                            ThemenID: action.payload.ThemenID,
                            FragenID: action.payload.FragenID,
                            Auswahl: action.payload.Auswahl,
                            Kommentar: "",
                            Art: 1,
                            Pflicht: action.payload.Pflicht
                        }]
                }
            }
        case "setAntwort":
            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    Antworten:
                        [...state.outgoingItem.Antworten.filter(f => f.ThemenID !== action.payload.ThemenID || (f.ThemenID === action.payload.ThemenID && f.Art !== 2)),
                        {
                            ThemenID: action.payload.ThemenID,
                            FragenID: action.payload.FragenID,
                            Auswahl: null,
                            Kommentar: "",
                            Art: 2,
                            Pflicht: action.payload.Pflicht
                        }]
                }
            }
        case "setAntwortKommentar":
            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    Antworten:
                        [...state.outgoingItem.Antworten.filter(f => f.ThemenID !== action.payload.ThemenID || (f.ThemenID === action.payload.ThemenID && f.Art !== 3)),
                        {
                            ThemenID: action.payload.ThemenID,
                            FragenID: null,
                            Auswahl: null,
                            Kommentar: action.payload.Kommentar,
                            Art: 3,
                            Pflicht: action.payload.Pflicht
                        }]
                }
            }
        default:
            return {
                ...state
            }
    }
}

interface IStateContext {
    state: State
    dispatch: React.Dispatch<Action>
}

const intialState = {
    outgoingItem: {
        Title: "",
        FirstName: "",
        LastName: "",
        StudentID: "",
        StudentGuid: "",
        DegreeProgram: "",
        Deadline: "",
        allowPublicationReport: true,
        Report: {
            FileName: "",
            FileType: "",
            Content: ""
        } as DocumentItem,
        Antworten: [{ ThemenID: null, FragenID: null, Kommentar: "", Auswahl: null, Pflicht: false } as AntwortenItem] as AntwortenItem[],
    } as OutgoingItem,

    TempReportFilename: "",
    TempReportFiletype: "",
    validationDialogVisible: false,
    validationErrors: [""],
    isSaving: false,
    ready: false,
    authorized: false,
    submittedJustNow: false,
    submitted: false
} as State

export const ParentContext = createContext<IStateContext>({ state: intialState, dispatch: () => null });

const UploadReport: FC = () => {
    const { oAuthToken, studentGuid } = useContext(SessionContext)
    const [state, dispatch] = useReducer(outgoingReducer, intialState)
    useEffect(() => {
        (async () => {
            const student = await getOutgoingExchangeReport(oAuthToken, studentGuid)
            if (student) {
                let date = new Date(student.Deadline)
                date.setHours(23, 59, 59)
                if (date < new Date()) {
                    dispatch({ type: "setReady", payload: true })
                    dispatch({ type: "setApplicationDeadline", payload: date.toLocaleDateString() })
                    return
                }
                else {
                    dispatch({ type: "initial", payload: student })
                    dispatch({ type: "setReady", payload: true })
                    dispatch({ type: "setAuthorized", payload: student.Status.StatusID === 7 || student.Status.StatusID === 14 })
                    dispatch({
                        type: "setSubmitted", payload: (student.Status.StatusID !== 7 &&
                            student.Status.StatusID !== 14) || (student.Status.StatusID === 7 && student.Report != null)
                    })
                    dispatch({ type: "setAllowPublicationReport", payload: true })
                }
            }
        })()
    }, [oAuthToken, studentGuid])

    let fileReader: FileReader = new FileReader();
    let tempFileName: string = "";
    let tempFileTyp: string = "";
    const handleFileRead = () => {
        let content = ""
        if (typeof fileReader.result === "string") {
            content = fileReader.result.split(",")[1]
        }
        if (content) {
            //dispatch({ type: "setReport", payload: { FileName: state.TempReportFilename, FileType: state.TempReportFiletype, Content: content.toString() } })
            dispatch({ type: "setReport", payload: { FileName: tempFileName, FileType: tempFileTyp, Content: content.toString() } })
        }
    }

    const reportChanged = (selectorFiles: FileList | null) => {
        if (selectorFiles != null) {
            let file = selectorFiles[0]
            /* dispatch({ type: "setTempReportFilename", payload: file.name })
            dispatch({ type: "setTempReportFiletype", payload: file.type }) */
            tempFileName = file.name
            tempFileTyp = file.type
            fileReader.onloadend = handleFileRead
            fileReader.readAsDataURL(file)
        }
    }
    const toggleValidationDialogVisibility = useCallback(() => {
        dispatch({ type: "setValidationDialogVisible", payload: !state.validationDialogVisible })
    }, [state.validationDialogVisible])

    const showSaveStatus = useCallback((success: boolean, msg?: string) => {
        let popup = $("#popup-panel")
        let content = $("#popup-content")
        if (success) {
            content.removeClass("alert-danger")
            content.addClass("alert-success")
            content.html("Saved Successfully!")
        } else {
            content.removeClass("alert-success")
            content.addClass("alert-danger")
            content.html("Failed to Save")
        }
        popup.show()
        setTimeout(() => hideSaveStatus(), 2000)
    }, [])

    const hideSaveStatus = () => {
        let popup = $("#popup-panel")
        popup.fadeOut(800)
    }

    const isValid = useCallback((): boolean => {
        let valid = true
        let errors = []

        if (!state.outgoingItem.Report) {
            valid = false
            errors.push("Exchange report not uploaded")
        }
        else {
            if (state.TempReportFiletype != null && state.TempReportFiletype !== "" && !state.TempReportFiletype.toLowerCase().endsWith("pdf")) {
                valid = false
                errors.push("Only pdf file is allowed for Exchange report")
            }
        }
        if (state.outgoingItem.Antworten === null) {
            valid = false
            errors.push("Please complete evaluation")
        }
        else {
            if (state.outgoingItem.PflichtAntworten !== null && state.outgoingItem.Antworten.filter(i => i.Pflicht === true).length < state.outgoingItem.PflichtAntworten) {
                valid = false
                errors.push("Please complete evaluation")
            }
        }
        dispatch({ type: "setValidationErrors", payload: errors })
        return valid
    }, [state.TempReportFiletype, state.outgoingItem.Report, state.outgoingItem.Antworten, state.outgoingItem.PflichtAntworten])

    const submit = useCallback(async () => {

        if (!isValid()) {
            toggleValidationDialogVisibility()
            return
        }
        dispatch({ type: "setIsSaving", payload: true })
        const modifiedItem: OutgoingReportSaveRequest = {
            ExchangeReport: {
                FileName: state.outgoingItem.Report.FileName,
                FileType: state.outgoingItem.Report.FileType,
                Content: state.outgoingItem.Report.Content
            },
            Antworten: state.outgoingItem.Antworten,
            allowPublicationReport: state.outgoingItem.allowPublicationReport,
            StudentGuid: state.outgoingItem.StudentGuid
        }

        const response = await uploadOutgoingExchangeReport(oAuthToken,
            {
                ...modifiedItem,
            })
        dispatch({ type: "setIsSaving", payload: false })
        if (response === "200") {
            dispatch({ type: "setSubmitted", payload: true })
            dispatch({ type: "setSubmittedJustNow", payload: true })
        }
        showSaveStatus(response === "200")
    }, [state.outgoingItem.Report, state.outgoingItem.Antworten, state.outgoingItem.allowPublicationReport, state.outgoingItem.StudentGuid,
        oAuthToken, showSaveStatus, isValid, toggleValidationDialogVisibility,])

    if (!state.ready) return <LoadingAnimation />;
    if (!state.authorized) return <div className="full-width text-centered"><h1>Access denied</h1>{state.applicationDeadline && <div><h4>Unfortunately the deadline for uploading your report is already over</h4><h4>Please contact the IMC International Relations Team (<a href="mailto:international@fh-krems.ac.at">international@fh-krems.ac.at</a>) for further information.</h4><h4>Application Deadline: {state.applicationDeadline}</h4></div>}</div>;
    if (state.submitted) return <div className="full-width text-centered">{state.submittedJustNow === true && <h1>Thank you for submitting your exchange report!</h1>}{state.submittedJustNow === false && <h1>Report already uploaded</h1>}<h4>If you have any questions concerning your report upload, please contact the IMC International Relations Team (<a href="mailto:international@fh-krems.ac.at">international@fh-krems.ac.at</a>).</h4></div>;
    return <div>
        <div id="header">
            <div id="banner" className="container">
                <a id="logo" title="Startseite" rel="noopener noreferrer" target="_blank" href="http://www.imc.ac.at"><img src="img/logo_IMC_UAS_Krems_quer_schwarz_PRINT.png" alt="IMC Fachhochschule Krems GesmbH - University of Applied Sciences Austria" /></a>
            </div>
        </div>
        <div id="portal-columns" className="row">
            <div id="portal-columns-content" className="container">
                <h1>Student Application</h1>
                <p className="description"></p>
                <div className="form-info">
                    <p className="discreet">Submitting is only permitted if all required fields (marked with <span className="red">*</span>) are filled out and documents are uploaded.</p>
                </div>
                <span></span>

                <form id="mainform" >
                    <fieldset>
                        <legend><h3>Personal Data</h3></legend>
                        <div className="mt-1">
                            <table className="borderless full-width align-left">
                                <tbody>
                                    <tr>
                                        <th>First Name</th><td>{state.outgoingItem.FirstName}</td>
                                    </tr>
                                    <tr>
                                        <th>Last Name</th><td>{state.outgoingItem.LastName}</td>
                                    </tr>
                                    <tr>
                                        <th>IMC Degree Programme</th><td>{state.outgoingItem.DegreeProgram}</td>
                                    </tr>
                                    <tr>
                                        <th>University</th><td>{state.outgoingItem.PartnerUni}</td>
                                    </tr>
                                    <tr>
                                        <th></th><td>{state.outgoingItem.OrtPartnerUni}, {state.outgoingItem.NationPartnerUni}</td>
                                    </tr>
                                    <tr>
                                        <th>Mobility</th><td>{state.outgoingItem.Mobilitaet}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend><h3>Please upload your exchange report here<span className="red">*</span></h3></legend>
                        <div>
                            <p>Upload your Report as a PDF document. Other files types will be ignored!</p>
                        </div>
                        <div className="small">
                            <input id="report" type="file" accept="application/pdf" onChange={(e) => reportChanged(e.target.files)} />
                            <div>{(!state.outgoingItem.Report) ?
                                "" : `Last uploaded: ${state.outgoingItem.Report.FileName}`}</div>
                        </div>
                        <br />
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td width="50px">
                                            <RadioButton
                                                id="radioPublicationReportYes"
                                                name="adioPublicationReport"
                                                value={"1"}
                                                label={""}
                                                checked={state.outgoingItem.allowPublicationReport ? state.outgoingItem.allowPublicationReport : true} setChecked={(newValue: boolean) => { dispatch({ type: "setAllowPublicationReport", payload: newValue }) }}
                                                setValue={(newValue: string) => { }} />
                                        </td>
                                        <td>
                                            I have read and understood the corresponding&nbsp;
                                            <a href="https://processes.fh-krems.ac.at/ims.net/Common/GetDoc.aspx/301567/348558/DE/Consent_report_exchange_semester_FHF-5-0300.pdf" target="_blank" rel="noopener noreferrer" download>declaration of consent</a>
                                            &nbsp;and agree with the publication of my exchange report in full knowledge of this&nbsp;
                                            <a href="https://processes.fh-krems.ac.at/ims.net/Common/GetDoc.aspx/301567/348558/DE/Consent_report_exchange_semester_FHF-5-0300.pdf" target="_blank" rel="noopener noreferrer" download>declaration of consent</a>
                                        </td>
                                    </tr>
                                    <tr style={{ height: '20px' }}>
                                        <td></td><td></td>
                                    </tr>
                                    <tr>
                                        <td width="50px">
                                            <RadioButton
                                                id="radioPublicationReportNo"
                                                name="adioPublicationReport"
                                                value={"0"}
                                                label={""}
                                                checked={state.outgoingItem.allowPublicationReport ? !state.outgoingItem.allowPublicationReport : false} setChecked={(newValue: boolean) => { dispatch({ type: "setAllowPublicationReport", payload: newValue }) }}
                                                setValue={(newValue: string) => { }} />
                                        </td>
                                        <td>
                                            I do NOT agree with the publication of my exchange report
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <legend><h3>Please complete a short evaluation on your exchange abroad<span className="red">*</span></h3></legend>
                            <div>
                                <p>Your evaluation answers and comments will only be used for IMC internal purposes and will neither be published nor forwarded to any other students or third parties! Thanks a lot for your cooperation!</p>
                            </div>
                            <div>
                                <ParentContext.Provider value={{ state, dispatch }}>
                                    <Evaluation />
                                </ParentContext.Provider>
                            </div>
                        </div>
                        <div>
                        </div>

                    </fieldset>
                    {state.isSaving
                        ? (<div style={{ height: '70px' }}>
                            <div className="loading-holder">
                                <ReactLoading type="spin" color="rgba(47,72,102,1)" width={50} height={50} />
                            </div>
                        </div>)
                        : (<div style={{ height: '130px', marginTop: '20px' }}>
                            <fieldset>
                                <ul className="form">
                                    <li><Button className="button orange right w-100" type="button" primary={true} onClick={submit} icon="check">&nbsp;Submit</Button></li>
                                </ul>
                            </fieldset>
                        </div>)
                    }
                </form>
            </div>
        </div>
        {state.validationDialogVisible && (<ValidationDialog errors={state.validationErrors} onClose={toggleValidationDialogVisibility} />)}
        <div id="popup-panel" className="popup-panel" >
            <div id="popup-content" className="alert alert-success full-width text-centered"></div>
        </div>
        <div id="portal-footer" className="row">
            <div id="portal-footer-content" className="container clearfix">
                <hr />
                <dl className="g160 " id="more-websites">
                    <dt><span id="footercontrol_Label_more_website">More Information</span></dt>
                    <dd id="footercontrol_websites_oeh"><a href="https://edesktop.fh-krems.ac.at/knowledgebase/mobility/outgoing/SitePages/Home.aspx" id="footercontrol_a_websites_oeh" title="​​​Information for Outgoing Exchange Students" className="external-link" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_websites_oeh">​​​Information for Outgoing Exchange Students</span></a></dd>
                    <dd id="footercontrol_websites_stik"><a href="https://edesktop.fh-krems.ac.at/knowledgebase/mobility/studiesabroad/SitePages/Home.aspx" id="footercontrol_a_websites_stik" title="Erasmus+ Grant for Studies Abroad" className="external-link" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_websites_stik">Erasmus+ Grant for Studies Abroad</span></a></dd>
                </dl>

                <div className="vcard g240">

                    <dl className="adr" id="contact">
                        <dt><span id="footercontrol_label_Kontakt_H">Contact</span></dt>
                        <dd className="fn org organization-name"><abbr className="geo" title="48.412863;15.6002"><span><span id="footercontrol_FH_Label">IMC Fachhochschule Krems GesmbH</span></span></abbr></dd>
                        <dd className="street-address visuallyhidden">Piaristengasse 1</dd>
                        <dd><span className="postal-code">3500</span> <span className="locality">Krems</span> - <span className="country-name"><span id="footercontrol_label_country">Österreich</span></span> - <span className="region"><span id="footercontrol_label_region">Europa</span></span></dd>
                        <dd>T: <abbr className="type visuallyhidden" title="work pref">Work</abbr><span className="value">+43 2732 802 511</span></dd>
                        <dd>E: <a className="value" href="mailto:international@fh-krems.ac.at" rel="noopener noreferrer" target="_blank">international@fh-krems.ac.at</a></dd>
                        <dd className="visuallyhidden">I: <a href="http://www.fh-krems.ac.at/" className="url" rel="noopener noreferrer">http://www.fh-krems.ac.at/</a></dd>
                    </dl>
                </div>

                <dl className="g160 " id="help-services">
                    <dt><span id="footercontrol_label_help_services">Help and Services</span></dt>
                    <dd id="service-contact">
                        <a href="https://www.fh-krems.ac.at/en/international-focus/studying-abroad//" id="footercontrol_a_contact" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_kontakt">Contact</span></a>
                    </dd>
                    <dd id="service-direction">
                        <a href="https://www.fh-krems.ac.at/en/university/locations/" id="footercontrol_a_direction" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_direction">How to find us</span></a>
                    </dd>

                    <dd id="service-imprint">
                        <a href="https://www.fh-krems.ac.at/en/imprint/" id="footercontrol_a_imprint" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_imprint">Credits</span></a>
                    </dd>
                    <dd id="service-legality">
                        <a href="https://www.fh-krems.ac.at/fileadmin/public/downloads/rechtliches/information-on-data-protection.pdf" id="footercontrol_a_legality" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_legality">Legal notice</span></a>
                    </dd>
                    <dd id="service-eu_language">
                        <a href="https://rm.coe.int/CoERMPublicCommonSearchServices/DisplayDCTMContent?documentId=090000168045bb52" id="footercontrol_a_eu_language" rel="noopener noreferrer" target="_blank">
                            <span id="footercontrol_label_eu_language">Common European Framework of Reference for Languages (CEFR)</span>
                        </a>
                    </dd>
                </dl>
            </div>
        </div>
    </div>
}

export default UploadReport