import * as React from 'react';
import {
    FC,
    useEffect,
    useContext,
    useState
} from "react"
import SessionContext from "../../entities/SessionContext"
import { ParentContext } from "../Registration"
import { getLanguageList } from '../../DataAccess';
import DataItem from '../../entities/DataItem';
import LanguageItem from '../../entities/LanguageItem';
import DropDownSelect from '../dropDown'
import LanguageTableRow from './language-table-row';
import styles from "../css/registration.module.scss"
export interface Language {
    name: string;
    level: string;
    certificateFilename: string;
}

const LanguageTable: FC = () => {
    const { oAuthToken } = useContext(SessionContext)
    const { state, dispatch } = useContext(ParentContext);
    const [languagesList, setLanguagesList] = useState([] as DataItem[])
    const [motherToungeLanguagesList, setmotherToungeLanguagesList] = useState([] as DataItem[])
    const [languageLevels, setLanguageLevels] = useState([] as DataItem[])

    useEffect(() => {
        (async () => {
            const sprachen = await getLanguageList(oAuthToken);
            if (sprachen) {
                let level: DataItem[] = []
                level.push({ Text: "A1", Value: "A1" })
                level.push({ Text: "A2", Value: "A2" })
                level.push({ Text: "B1", Value: "B1" })
                level.push({ Text: "B2", Value: "B2" })
                level.push({ Text: "C1", Value: "C1" })
                level.push({ Text: "C2", Value: "C2" })
                setLanguageLevels(level)
                setmotherToungeLanguagesList(sprachen)
                sprachen.unshift({ Text: "-- None --", Value: "-1" })
                setLanguagesList(sprachen)
            }
        })()
    }, [oAuthToken]);

    const languageItem = (index: number): LanguageItem | null => {
        return state.outgoingItem.OtherLanguages?.find(f => f.Index === index) ?? null
    }

    return (
        <div>
            <div>
                <label htmlFor="mothertongue"><span>Mothertongue </span><em title="Required" className="fieldRequired">*</em></label>
                {motherToungeLanguagesList &&
                    <DropDownSelect data={motherToungeLanguagesList} required={true} validityStyles={false} className={styles.fullwidth} valueID={state.outgoingItem.MotherTongueID ? state.outgoingItem.MotherTongueID.toString() : ""} valueChanged={(newId: string) => { dispatch({ type: "setMotherTongueID", payload: Number(newId) }) }} />}
            </div>
            <table id="SprachTable">
                <thead>
                    <tr className="header language">
                        <th>Other Language(s)</th>
                        <th>Self Evaluation (According to table below)</th>
                        <th>Language Certificate (PDF)</th>
                    </tr>
                </thead>
                {languagesList.length > 0 &&
                    <tbody>
                        <LanguageTableRow
                            index={0}
                            language={languageItem(0)}
                            languages={languagesList}
                            languageLevels={languageLevels}
                        />
                        <LanguageTableRow
                            index={1}
                            language={languageItem(1)}
                            languages={languagesList}
                            languageLevels={languageLevels}
                        />
                        <LanguageTableRow
                            index={2}
                            language={languageItem(2)}
                            languages={languagesList}
                            languageLevels={languageLevels}
                        />
                    </tbody>
                }
            </table>
        </div>
    )
}
export default LanguageTable