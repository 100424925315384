
import React from 'react';
import {
    FC,
    useContext,
    useEffect,
    useReducer,
    useCallback
} from "react"
import SessionContext from "../entities/SessionContext"
import { getOutgoingKonto, saveOutgoingKonto } from '../DataAccess';
import OutgoingItem from '../entities/OutgoingItem';
import OutgoingKontoSaveRequest from '../entities/OutgoingKontoSaveRequest';
import LoadingAnimation from '../helper/loading.component';
import ReactLoading from 'react-loading';
import Textbox from './inputText'
import DatePicker from './inputDate'
import { Button } from '@progress/kendo-react-buttons';
import { ValidationDialog } from './dialogs/validation-dialog';
import $ from 'jquery';

interface State {
    outgoingItem: OutgoingItem
    submitted: boolean
    submittedJustNow: boolean
    validationErrors: string[]
    validationDialogVisible: boolean
    applicationDeadline: string
    isSaving: boolean
    ready: boolean
    authorized: boolean
}

type Action =
    {
        type: "initial"
        payload: OutgoingItem
    }
    | {
        type: "setIban"
        payload: string
    }
    | {
        type: "setBic"
        payload: string
    }
    | {
        type: "setBank"
        payload: string
    }
    | {
        type: "setKontoinhaber"
        payload: string
    }
    | {
        type: "setBeginn"
        payload: string
    }
    | {
        type: "setEnde"
        payload: string
    }
    | {
        type: "setValidationErrors"
        payload: string[]
    }
    | {
        type: "setValidationDialogVisible"
        payload: boolean
    }
    | {
        type: "setApplicationDeadline"
        payload: string
    }
    | {
        type: "setIsSaving"
        payload: boolean
    }
    | {
        type: "setReady"
        payload: boolean
    }
    | {
        type: "setAuthorized"
        payload: boolean
    }
    | {
        type: "setSubmittedJustNow"
        payload: boolean
    }
    | {
        type: "setSubmitted"
        payload: boolean
    }

const outgoingReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "setIban":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, IBAN: action.payload }
            }
        case "setBic":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, BIC: action.payload }
            }
        case "setBank":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, Bank: action.payload }
            }
        case "setKontoinhaber":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, Kontoinhaber: action.payload }
            }
        case "setBeginn":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, Beginn: action.payload }
            }
        case "setEnde":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, Ende: action.payload }
            }
        case "setSubmitted":
            return {
                ...state,
                submitted: action.payload
            }
        case "setSubmittedJustNow":
            return {
                ...state,
                submittedJustNow: action.payload
            }
        case "setAuthorized":
            return {
                ...state,
                authorized: action.payload
            }
        case "setReady":
            return {
                ...state,
                ready: action.payload
            }
        case "setApplicationDeadline":
            return {
                ...state,
                applicationDeadline: action.payload
            }
        case "setIsSaving":
            return {
                ...state,
                isSaving: action.payload
            }
        case "setValidationDialogVisible":
            return {
                ...state,
                validationDialogVisible: action.payload
            }
        case "setValidationErrors":
            return {
                ...state,
                validationErrors: action.payload
            }
        case "initial":
            return {
                ...state,
                outgoingItem: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

const intialState = {
    outgoingItem: {
        Title: "",
        FirstName: "",
        LastName: "",
        StudentID: "",
        StudentGuid: "",
        DegreeProgram: "",
        IBAN: "",
        BIC: "",
        Bank: "",
        Kontoinhaber: "",
        Deadline: "",
    } as OutgoingItem,
    validationDialogVisible: false,
    validationErrors: [""],
    isSaving: false,
    ready: false,
    authorized: false,
    submittedJustNow: false,
    submitted: false
} as State

const EditKonto: FC = () => {
    const { oAuthToken, studentGuid } = useContext(SessionContext)
    const [state, dispatch] = useReducer(outgoingReducer, intialState)
    useEffect(() => {
        (async () => {
            const student = await getOutgoingKonto(oAuthToken, studentGuid)
            if (student) {
                let date = new Date(student.Deadline)
                date.setHours(23, 59, 59)
                if (date < new Date()) {
                    dispatch({ type: "setReady", payload: true })
                    dispatch({ type: "setApplicationDeadline", payload: date.toLocaleDateString() })
                    return
                }
                else {
                    dispatch({ type: "initial", payload: student })
                    dispatch({ type: "setReady", payload: true })
                    dispatch({ type: "setAuthorized", payload: student.Status.StatusID === 7 })
                }
            }
        })()
    }, [oAuthToken, studentGuid])

    const toggleValidationDialogVisibility = useCallback(() => {
        dispatch({ type: "setValidationDialogVisible", payload: !state.validationDialogVisible })
    }, [state.validationDialogVisible])

    const showSaveStatus = useCallback((success: boolean, msg?: string) => {
        let popup = $("#popup-panel")
        let content = $("#popup-content")
        if (success) {
            content.removeClass("alert-danger")
            content.addClass("alert-success")
            content.html("Saved Successfully!")
        } else {
            content.removeClass("alert-success")
            content.addClass("alert-danger")
            content.html("Failed to Save")
        }
        popup.show()
        setTimeout(() => hideSaveStatus(), 2000)
    }, [])

    const hideSaveStatus = () => {
        let popup = $("#popup-panel")
        popup.fadeOut(800)
    }

    const isValid = useCallback((): boolean => {
        let valid = true
        let errors = []
        if (!state.outgoingItem.Kontoinhaber) {
            valid = false
            errors.push("Account holder")
        }
        if (!state.outgoingItem.IBAN) {
            valid = false
            errors.push("IBAN")
        }
        if (!state.outgoingItem.BIC) {
            valid = false
            errors.push("BIC")
        }
        if (!state.outgoingItem.Bank) {
            valid = false
            errors.push("Bank")
        }
        if (!state.outgoingItem.Beginn) {
            valid = false
            errors.push("Begin")
        }
        if (!state.outgoingItem.Ende) {
            valid = false
            errors.push("End")
        }

        dispatch({ type: "setValidationErrors", payload: errors })
        return valid
    }, [state.outgoingItem.Kontoinhaber, state.outgoingItem.IBAN, state.outgoingItem.BIC, state.outgoingItem.Bank, state.outgoingItem.Beginn, state.outgoingItem.Ende])

    const submit = useCallback(async () => {

        if (!isValid()) {
            toggleValidationDialogVisibility()
            return
        }
        dispatch({ type: "setIsSaving", payload: true })
        const modifiedItem: OutgoingKontoSaveRequest = {
            Kontoinhaber: state.outgoingItem.Kontoinhaber,
            IBAN: state.outgoingItem.IBAN,
            BIC: state.outgoingItem.BIC,
            Bank: state.outgoingItem.Bank,
            Beginn: state.outgoingItem.Beginn,
            Ende: state.outgoingItem.Ende,
            StudentGuid: state.outgoingItem.StudentGuid
        }

        const response = await saveOutgoingKonto(oAuthToken,
            {
                ...modifiedItem,
            })
        dispatch({ type: "setIsSaving", payload: false })
        if (response === "200") {
            dispatch({ type: "setSubmitted", payload: true })
            dispatch({ type: "setSubmittedJustNow", payload: true })
        }
        showSaveStatus(response === "200")
    }, [state.outgoingItem.Kontoinhaber, state.outgoingItem.IBAN, state.outgoingItem.BIC, state.outgoingItem.Bank, state.outgoingItem.Beginn,
    state.outgoingItem.Ende, state.outgoingItem.StudentGuid, oAuthToken, showSaveStatus, isValid, toggleValidationDialogVisibility,])

    if (!state.ready) return <LoadingAnimation />;
    if (!state.authorized) return <div className="full-width text-centered"><h1>Access denied</h1>{state.applicationDeadline && <div><h4>Unfortunately the deadline is already over</h4><h4>Please contact the IMC International Relations Team (<a href="mailto:international@fh-krems.ac.at">international@fh-krems.ac.at</a>) for further information.</h4><h4>Application Deadline: {state.applicationDeadline}</h4></div>}</div>;
    if (state.submitted) return <div className="full-width text-centered">{state.submittedJustNow === true && <h1>Thank you for submitting data!</h1>}{state.submittedJustNow === false && <h1>Data already submitted</h1>}<h4>If you have any questions concerning your report upload, please contact the IMC International Relations Team (<a href="mailto:international@fh-krems.ac.at">international@fh-krems.ac.at</a>).</h4></div>;
    return <div>
        <div id="header">
            <div id="banner" className="container">
                <a id="logo" title="Startseite" rel="noopener noreferrer" target="_blank" href="http://www.imc.ac.at"><img src="img/logo_IMC_UAS_Krems_quer_schwarz_PRINT.png" alt="IMC Fachhochschule Krems GesmbH - University of Applied Sciences Austria" /></a>
            </div>
        </div>
        <div id="portal-columns" className="row">
            <div id="portal-columns-content" className="container">
                <h1>Student Application</h1>
                <p className="description"></p>
                <div className="form-info">
                    <p className="discreet">Submitting is only permitted if all required fields (marked with <span className="red">*</span>) are filled out.</p>
                </div>
                <span></span>

                <form id="mainform" >
                    <fieldset>
                        <legend><h3>Personal Data</h3></legend>
                        <div className="mt-1">
                            <table className="borderless full-width align-left">
                                <tbody>
                                    <tr>
                                        <th>First Name</th><td>{state.outgoingItem.FirstName}</td>
                                    </tr>
                                    <tr>
                                        <th>Last Name</th><td>{state.outgoingItem.LastName}</td>
                                    </tr>
                                    <tr>
                                        <th>IMC Degree Programme</th><td>{state.outgoingItem.DegreeProgram}</td>
                                    </tr>
                                    <tr>
                                        <th>University</th><td>{state.outgoingItem.PartnerUni}</td>
                                    </tr>
                                    <tr>
                                        <th></th><td>{state.outgoingItem.OrtPartnerUni}, {state.outgoingItem.NationPartnerUni}</td>
                                    </tr>
                                    <tr>
                                        <th>Mobility</th><td>{state.outgoingItem.Mobilitaet}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend><h3>Needed information </h3></legend>
                        <h3 className="header headline">Bank details</h3>
                        <ol className="form">
                            <li>
                                <label htmlFor="Kontoinhaber"><span>Account holder </span><em title="Required" className="fieldRequired">*</em></label>
                                <Textbox name="Kontoinhaber" id="kontoinhaber" className={undefined} required={true} maxLength={150} value={state.outgoingItem.Kontoinhaber} setText={(newText: string) => { dispatch({ type: "setKontoinhaber", payload: newText }) }} />
                            </li>
                            <li>
                                <label htmlFor="IBAN"><span>IBAN </span><em title="Required" className="fieldRequired">*</em></label>
                                <Textbox name="IBAN" id="iban" className={undefined} required={true} maxLength={50} value={state.outgoingItem.IBAN} setText={(newText: string) => { dispatch({ type: "setIban", payload: newText }) }} />
                            </li>
                            <li>
                                <label htmlFor="BIC"><span>BIC </span><em title="Required" className="fieldRequired">*</em></label>
                                <Textbox name="BIC" id="bic" className={undefined} required={true} maxLength={50} value={state.outgoingItem.BIC} setText={(newText: string) => { dispatch({ type: "setBic", payload: newText }) }} />
                            </li>
                            <li>
                                <label htmlFor="Bank"><span>Bank </span><em title="Required" className="fieldRequired">*</em></label>
                                <Textbox name="Bank" id="bank" className={undefined} required={true} maxLength={150} value={state.outgoingItem.Bank} setText={(newText: string) => { dispatch({ type: "setBank", payload: newText }) }} />
                            </li>
                        </ol>
                    </fieldset>
                    <br />
                    <fieldset>
                        <h3 className="header headline">Erasmus data</h3>
                        <ol className="form">
                            <li>
                                <label htmlFor="Beginn"><span>Begin </span><em title="Required" className="fieldRequired">*</em></label>
                                <DatePicker name="Beginn" id="beginn" className={undefined} required={true} value={new Date(state.outgoingItem.Beginn)} setDate={(newDate: Date) => { dispatch({ type: "setBeginn", payload: newDate != null ? newDate.toDateString() : "" }) }} />
                            </li>
                            <li>
                                <label htmlFor="Ende"><span>End </span><em title="Required" className="fieldRequired">*</em></label>
                                <DatePicker name="Ende" id="ende" className={undefined} required={true} value={new Date(state.outgoingItem.Ende)} setDate={(newDate: Date) => { dispatch({ type: "setEnde", payload: newDate != null ? newDate.toDateString() : "" }) }} />
                            </li>
                        </ol>
                    </fieldset>
                    {state.isSaving
                        ? (<div style={{ height: '70px' }}>
                            <div className="loading-holder">
                                <ReactLoading type="spin" color="rgba(47,72,102,1)" width={50} height={50} />
                            </div>
                        </div>)
                        : (<div style={{ height: '130px', marginTop: '20px' }}>
                            <fieldset>
                                <ul className="form">
                                    <li><Button className="button orange right w-100" type="button" primary={true} onClick={submit} icon="check">&nbsp;Submit</Button></li>
                                </ul>
                            </fieldset>
                        </div>)
                    }
                </form>
            </div>
        </div>
        {state.validationDialogVisible && (<ValidationDialog errors={state.validationErrors} onClose={toggleValidationDialogVisibility} />)}
        <div id="popup-panel" className="popup-panel" >
            <div id="popup-content" className="alert alert-success full-width text-centered"></div>
        </div>
        <div id="portal-footer" className="row">
            <div id="portal-footer-content" className="container clearfix">
                <hr />
                <dl className="g160 " id="more-websites">
                    <dt><span id="footercontrol_Label_more_website">More Information</span></dt>
                    <dd id="footercontrol_websites_oeh"><a href="https://edesktop.fh-krems.ac.at/knowledgebase/mobility/outgoing/SitePages/Home.aspx" id="footercontrol_a_websites_oeh" title="​​​Information for Outgoing Exchange Students" className="external-link" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_websites_oeh">​​​Information for Outgoing Exchange Students</span></a></dd>
                    <dd id="footercontrol_websites_stik"><a href="https://edesktop.fh-krems.ac.at/knowledgebase/mobility/studiesabroad/SitePages/Home.aspx" id="footercontrol_a_websites_stik" title="Erasmus+ Grant for Studies Abroad" className="external-link" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_websites_stik">Erasmus+ Grant for Studies Abroad</span></a></dd>
                </dl>

                <div className="vcard g240">

                    <dl className="adr" id="contact">
                        <dt><span id="footercontrol_label_Kontakt_H">Contact</span></dt>
                        <dd className="fn org organization-name"><abbr className="geo" title="48.412863;15.6002"><span><span id="footercontrol_FH_Label">IMC Fachhochschule Krems GesmbH</span></span></abbr></dd>
                        <dd className="street-address visuallyhidden">Piaristengasse 1</dd>
                        <dd><span className="postal-code">3500</span> <span className="locality">Krems</span> - <span className="country-name"><span id="footercontrol_label_country">Österreich</span></span> - <span className="region"><span id="footercontrol_label_region">Europa</span></span></dd>
                        <dd>T: <abbr className="type visuallyhidden" title="work pref">Work</abbr><span className="value">+43 2732 802 511</span></dd>
                        <dd>E: <a className="value" href="mailto:international@fh-krems.ac.at" rel="noopener noreferrer" target="_blank">international@fh-krems.ac.at</a></dd>
                        <dd className="visuallyhidden">I: <a href="http://www.fh-krems.ac.at/" className="url" rel="noopener noreferrer">http://www.fh-krems.ac.at/</a></dd>
                    </dl>
                </div>

                <dl className="g160 " id="help-services">
                    <dt><span id="footercontrol_label_help_services">Help and Services</span></dt>
                    <dd id="service-contact">
                        <a href="https://www.fh-krems.ac.at/en/international-focus/studying-abroad//" id="footercontrol_a_contact" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_kontakt">Contact</span></a>
                    </dd>
                    <dd id="service-direction">
                        <a href="https://www.fh-krems.ac.at/en/university/locations/" id="footercontrol_a_direction" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_direction">How to find us</span></a>
                    </dd>

                    <dd id="service-imprint">
                        <a href="https://www.fh-krems.ac.at/en/imprint/" id="footercontrol_a_imprint" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_imprint">Credits</span></a>
                    </dd>
                    <dd id="service-legality">
                        <a href="https://www.fh-krems.ac.at/fileadmin/public/downloads/rechtliches/information-on-data-protection.pdf" id="footercontrol_a_legality" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_legality">Legal notice</span></a>
                    </dd>
                    <dd id="service-eu_language">
                        <a href="https://rm.coe.int/CoERMPublicCommonSearchServices/DisplayDCTMContent?documentId=090000168045bb52" id="footercontrol_a_eu_language" rel="noopener noreferrer" target="_blank">
                            <span id="footercontrol_label_eu_language">Common European Framework of Reference for Languages (CEFR)</span>
                        </a>
                    </dd>
                </dl>
            </div>
        </div>
    </div>
}
export default EditKonto