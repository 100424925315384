import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

interface DsgvoDialogProps {
    onClose: () => void;
}

/**
 * Shown when attempting to save/submit without checking dsgvo box
 */
export class DsgvoDialog extends React.Component<DsgvoDialogProps> {
    constructor(props: DsgvoDialogProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Dialog title={"General Data Protection Regulation"} onClose={this.props.onClose}>
                <p>You have to agree to the processing of your data before you can continue.</p>
                <p>Read the linked documents and check the box at the bottom of the form.</p>
                <DialogActionsBar>
                    <button className="k-button" onClick={this.props.onClose}>OK</button>
                </DialogActionsBar>
            </Dialog>
        );
    }
}