import * as React from 'react';
import {
    FC,
    useEffect,
    useContext,
    useState
} from "react"
import SessionContext from "../../entities/SessionContext"
import { ParentContext } from "../Registration"
import { getNationList, getUniversitiesList } from '../../DataAccess';
import UniversityTableRow from './university-table-row'
import DataItem from "../../entities/DataItem"
import University from "../../entities/UniversityListItem"

const UniversitiesTable: FC = () => {
    const { oAuthToken } = useContext(SessionContext)
    const { state } = useContext(ParentContext);
    const [nationList, setNationList] = useState([] as DataItem[])
    const [uniList, setUniList] = useState([] as University[])
    const [uniSelectList, setUniSelectList] = useState([] as DataItem[])
    const [doubleDegree, setDoubleDegree] = useState({ 0: false } as { [id: number]: boolean })
    useEffect(() => {
        (async () => {
            const nations = await getNationList(oAuthToken);
            if (nations)
                setNationList(nations)
        })()
    }, [oAuthToken]);

    useEffect(() => {
        (async () => {
            const unis = await getUniversitiesList(oAuthToken, state.outgoingItem.StudiengangID.toString());
            if (unis) {
                let universityArray: DataItem[] = []
                let tempDoubleDegree: { [id: number]: boolean } = { 0: false }
                for (let uni of unis) {
                    universityArray.push({
                        Text: uni.Name,
                        Value: uni.ItemId.toString()
                    });

                    //build dictionary<UniversityId, doubledegree>, so you can later comfortably check if a double degree is available for a specific university
                    let universityAndDoubleDegreePairs = uni.Studiengaenge.split(",")

                    for (let pair of universityAndDoubleDegreePairs) {
                        let studiengangAndDoubleDegree = pair.split(":")
                        let studiengangID = Number(studiengangAndDoubleDegree[0])
                        if (studiengangID !== state.outgoingItem.StudiengangID) continue // not the course of our current student, so skip it                        
                        let value = (studiengangAndDoubleDegree[1] === "true")
                        tempDoubleDegree[uni.ItemId] = value
                    }
                }
                //sort universities in alphabetical order
                universityArray.sort((a, b) => {
                    var an = a.Text.toUpperCase()
                    var bn = b.Text.toUpperCase()
                    if (an > bn) return 1
                    if (an < bn) return -1
                    return 0
                })
                universityArray.unshift({ Text: "-- None --", Value: "-1" })
                setUniSelectList(universityArray)
                setUniList(unis);
                setDoubleDegree(tempDoubleDegree)
            }
        })()
    }, [oAuthToken, state.outgoingItem.StudiengangID]);


    return (
        <table id="SprachTable">
            <thead>
                <tr className="header">
                    <th style={{ width: '50px' }}>#</th>
                    {state.outgoingItem.IsMaster && <th style={{ width: '100px' }}>Freemover</th>}
                    <th>University</th>
                    <th>Country</th>
                    <th style={{ width: '120px' }}>Dual Degree</th>
                    <th>Remarks</th>
                </tr>
            </thead>
            {doubleDegree && (
                <tbody>
                    <UniversityTableRow
                        preference={1}
                        doubleDegree={doubleDegree}
                        nations={nationList}
                        universitiesSelect={uniSelectList}
                        universities={uniList}
                    />
                    <UniversityTableRow
                        preference={2}
                        doubleDegree={doubleDegree}
                        nations={nationList}
                        universitiesSelect={uniSelectList}
                        universities={uniList}
                    />
                    <UniversityTableRow
                        preference={3}
                        doubleDegree={doubleDegree}
                        nations={nationList}
                        universitiesSelect={uniSelectList}
                        universities={uniList}
                    />
                </tbody>)}
        </table>
    )
}
export default UniversitiesTable