import AccessTokenResponse from "./entities/AccessTokenResponse"
import OAuthRequestParameters from './entities/OAuthRequestParameters'
import DataItem from "./entities/DataItem"
import OutgoingItem from "./entities/OutgoingItem"
import University from "./entities/UniversityListItem"
import OutgoingSaveRequest from "./entities/OutgoingSaveRequest"
import OutgoingReportSaveRequest from "./entities/OutgoingReportSaveRequest"
import OutgoingKontoSaveRequest from "./entities/OutgoingKontoSaveRequest"
import EvalThemenItem from "./entities/EvalThemenItem"
export const jsonMIMEType = "application/json"
export const jsonContentTypeHTTPHeader = ["Content-Type", jsonMIMEType]
export function getOAuthAuthorizationHTTPHeader(accessToken: string) {
    return ["Authorization", "Bearer " + accessToken]
}


export async function getOAuthToken() {
    try {
        const username = process.env.REACT_APP_DATA_API_USERNAME
        const password = encodeURIComponent(process.env.REACT_APP_DATA_API_PASSWORD !== undefined ? process.env.REACT_APP_DATA_API_PASSWORD : "")
        const oauthCredentials = {
            username: username,
            password: password,
            grant_type: "password"
        } as OAuthRequestParameters
        const response = await fetch(`${process.env.REACT_APP_DATA_API}/Token`,
            {
                method: "POST",
                headers: [["Content-Type", "application/x-www-form-urlencoded"]],
                body: Object.keys(oauthCredentials).map(value => `${value}=${oauthCredentials[value]}`).join('&')
            })

        if (!response.ok)
            throw Error(response.statusText)

        return await response.json().then(value => value as AccessTokenResponse)
    } catch (error) {
        console.error(error)
    }
}

export async function getNationList(accessToken: string) {

    try {
        const response = await fetch(`${process.env.REACT_APP_DATA_API}/api/Outgoing/GetNationen`,
            {
                method: 'get',
                headers: new Headers(
                    {
                        'Authorization': 'Bearer ' + accessToken,
                    })
            })

        if (!response.ok)
            throw Error(response.statusText)

        return (await response.json()) as DataItem[]
    } catch (error) {
        console.error(error)
    }
}

export async function getLanguageList(accessToken: string) {

    try {
        const response = await fetch(`${process.env.REACT_APP_DATA_API}/api/Outgoing/GetSprachen`,
            {
                method: 'get',
                headers: new Headers(
                    {
                        'Authorization': 'Bearer ' + accessToken,
                    })
            })

        if (!response.ok)
            throw Error(response.statusText)

        return (await response.json() as DataItem[])
    } catch (error) {
        console.error(error)
    }
}

export async function getUniversitiesList(accessToken: string, studiengangID: string) {

    try {
        const response = await fetch(`${process.env.REACT_APP_DATA_API}/api/Outgoing/GetPartnerUniversities/${studiengangID}`,
            {
                method: 'get',
                headers: new Headers(
                    {
                        'Authorization': 'Bearer ' + accessToken,
                    })
            })

        if (!response.ok)
            throw Error(response.statusText)

        return await response.json() as University[]
    } catch (error) {
        console.error(error)
    }
}

export async function getOutgoingItem(accessToken: string, studentGuid: string) {
    try {
        const response = await fetch(`${process.env.REACT_APP_DATA_API}/api/Outgoing/GetOutgoing/${studentGuid}`,
            {
                method: 'get',
                headers: new Headers(
                    {
                        'Authorization': 'Bearer ' + accessToken,
                    })
            })

        if (!response.ok)
            throw Error(response.statusText)

        return await response.json() as OutgoingItem
    } catch (error) {
        console.error(error)
    }
}

export async function updateOutgoingItem(accessToken: string, editedItem: OutgoingSaveRequest) {
    try {
        const response = await fetch(`${process.env.REACT_APP_DATA_API}/api/Outgoing/SaveOutgoing/`,
            {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedItem)
            })

        if (!response.ok)
            throw Error(response.statusText)

        return await response.status.toString()
    } catch (error) {
        console.error(error)
    }
}

export async function submitOutgoingItem(accessToken: string, editedItem: OutgoingSaveRequest) {
    try {
        const response = await fetch(`${process.env.REACT_APP_DATA_API}/api/Outgoing/Submit/`,
            {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedItem)
            })

        if (!response.ok)
            throw Error(response.statusText)

        return await response.status.toString()
    } catch (error) {
        console.error(error)
    }
}

export async function getOutgoingExchangeReport(accessToken: string, studentGuid: string) {
    try {
        const response = await fetch(`${process.env.REACT_APP_DATA_API}/api/Outgoing/GetOutgoingExchangeReport/${studentGuid}`,
            {
                method: 'get',
                headers: new Headers(
                    {
                        'Authorization': 'Bearer ' + accessToken,
                    })
            })

        if (!response.ok)
            throw Error(response.statusText)

        return await response.json() as OutgoingItem
    } catch (error) {
        console.error(error)
    }
}

export async function uploadOutgoingExchangeReport(accessToken: string, editedItem: OutgoingReportSaveRequest) {
    try {
        const response = await fetch(`${process.env.REACT_APP_DATA_API}/api/Outgoing/UploadOutgoingExchangeReport/`,
            {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedItem)
            })

        if (!response.ok)
            throw Error(response.statusText)

        return await response.status.toString()
    } catch (error) {
        console.error(error)
    }
}

export async function getOutgoingKonto(accessToken: string, studentGuid: string) {
    try {
        const response = await fetch(`${process.env.REACT_APP_DATA_API}/api/Outgoing/GetOutgoingKonto/${studentGuid}`,
            {
                method: 'get',
                headers: new Headers(
                    {
                        'Authorization': 'Bearer ' + accessToken,
                    })
            })

        if (!response.ok)
            throw Error(response.statusText)

        return await response.json() as OutgoingItem
    } catch (error) {
        console.error(error)
    }
}

export async function saveOutgoingKonto(accessToken: string, editedItem: OutgoingKontoSaveRequest) {
    try {
        const response = await fetch(`${process.env.REACT_APP_DATA_API}/api/Outgoing/SaveOutgoingKonto/`,
            {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedItem)
            })

        if (!response.ok)
            throw Error(response.statusText)

        return await response.status.toString()
    } catch (error) {
        console.error(error)
    }
}

export async function getEvalThemen(accessToken: string) {

    try {
        const response = await fetch(`${process.env.REACT_APP_DATA_API}/api/Outgoing/GetEvaluierungsThemen`,
            {
                method: 'get',
                headers: new Headers(
                    {
                        'Authorization': 'Bearer ' + accessToken,
                    })
            })

        if (!response.ok)
            throw Error(response.statusText)

        return (await response.json() as EvalThemenItem[])
    } catch (error) {
        console.error(error)
    }
}

