import React, { FC, useCallback } from 'react'
import {
    useState,
    useContext,
    useEffect
} from "react"
import DropDownSelect from '../dropDown';
import Textbox from '../inputText'
import { ParentContext } from "../Registration"
import UniversityItem from "../../entities/UniversityItem"
import University from "../../entities/UniversityListItem"
import styles from "../css/registration.module.scss"

interface UniversityTableRowProps {
    preference: number
    universitiesSelect: any[]
    universities: University[]
    nations: any[]
    doubleDegree: { [id: number]: boolean }
}

const UniversityTableRow: FC<UniversityTableRowProps> = (props) => {
    const getUniById = useCallback((id: number | null): University => {
        if (id === null || id < 0) return {} as University
        return props.universities.find((x) => x.ItemId === id) as University
    }, [props.universities])

    const { state, dispatch } = useContext(ParentContext)
    const [selectedFreemover, setFreemover] = useState(false)
    const [hiddenUni, setHiddenUni] = useState(true)
    const [selectedUni, setSelectedUni] = useState({} as University)
    const [selectedUniItem, setSelectedUniItem] = useState({} as UniversityItem)
    const [selectedDoubleDegree, setSelectedDoubleDegree] = useState(false)

    useEffect(() => {
        const uni = state.outgoingItem.Universities.find(f => f.Preference === props.preference)
        setSelectedUniItem(uni != null ? uni : {} as UniversityItem)
    }, [props.universitiesSelect, props.preference, state.outgoingItem.Universities]);

    useEffect(() => {
        if (selectedUniItem.UniversityID === null) {
            setFreemover(true)
            setHiddenUni(false)
        }
        else {
            setHiddenUni(false)
            let u = getUniById(selectedUniItem.UniversityID)
            setSelectedUni(u)
            setSelectedDoubleDegree(selectedUniItem.DoubleDegree)
        }
    }, [selectedUniItem, props.universities, getUniById]);

    return (
        <tr>
            <td><div className={styles.smallFont}>{props.preference}{props.preference === 1 ? <span className="red">*</span> : ''}</div></td>

            {state.outgoingItem.IsMaster &&
                <td>
                    <DropDownSelect data={[{ Text: "Yes", Value: "1" }, { Text: "No", Value: "0" }]} required={true}
                        className={styles.fullwidth}
                        valueID={selectedFreemover ? "1" : "0"}
                        validityStyles={false}
                        valueChanged={(newId: string) => { setFreemover(newId === "1") }} />
                </td>}

            {state.outgoingItem.IsMaster && selectedFreemover === true ?
                <td>
                    <div className={styles.smallFont}>
                        <Textbox name="uniBezeichnung" id="uniBezeichnung"
                            className={selectedUniItem === null || (selectedUniItem !== null && selectedUniItem.University === null) ? (styles.fullwidth + "k-state-invalid") : styles.fullwidth}
                            required={false} maxLength={500} value={selectedUniItem?.University}
                            setText={(newText: string) => { dispatch({ type: "setUniUniversity", payload: { university: newText, preference: props.preference } }) }} />
                    </div>
                </td>
                :
                <td><div className={styles.smallFont}>
                    <DropDownSelect data={props.universitiesSelect} required={props.preference === 1 ? true : false}
                        className={styles.fullwidth} validityStyles={false}
                        valueID={(selectedUniItem?.UniversityID ?? "-1").toString()}
                        valueChanged={(newId: string) => {
                            dispatch({
                                type: "setUniUniversityID",
                                payload: {
                                    preference: props.preference,
                                    universityID: newId !== "" ? Number(newId) : null
                                }
                            })
                            let u = getUniById(newId !== "" ? Number(newId) : 0);
                            setSelectedUni(u)
                            setHiddenUni(newId === "-1")
                        }} />
                </div>
                </td>
            }

            {state.outgoingItem.IsMaster && selectedFreemover === true ?
                <td>
                    <div className={styles.smallFont}>
                        <DropDownSelect data={props.nations} required={true} className={styles.fullwidth}
                            validityStyles={false}
                            valueID={selectedUniItem ?
                                (selectedUniItem.NationID ?? 0).toString() : ""}
                            valueChanged={(newId: string) => {
                                dispatch({
                                    type: "setUniNationID",
                                    payload: {
                                        preference: props.preference,
                                        nationID: newId !== "" ? Number(newId) : null
                                    }
                                })
                            }} />
                    </div>
                </td>
                :
                <td><div className={styles.smallFont}>{!hiddenUni && (selectedUni?.NationEn)}</div></td>
            }

            {state.outgoingItem.IsMaster && selectedFreemover === true ?
                <td><div className={styles.smallFont}></div></td>
                :
                <td><div className={styles.smallFont}>
                    {!hiddenUni ? (props.doubleDegree[selectedUni?.ItemId] === true
                        ?
                        <DropDownSelect data={[{ Text: "Yes", Value: "1" }, { Text: "No", Value: "0" }]} required={true}
                            className={styles.fullwidth}
                            valueID={selectedUniItem ?
                                selectedUniItem.DoubleDegree === true ?
                                    "1" : "0" : selectedDoubleDegree ? "1" : "0"}
                            validityStyles={false}
                            valueChanged={(newId: string) => {
                                dispatch({
                                    type: "setUniDoubleDegree",
                                    payload: {
                                        preference: props.preference,
                                        doubledegree: newId !== "" ? newId === "1" : false
                                    }
                                })
                            }} />
                        : 'Not available') : ""}
                </div>
                </td>
            }

            {state.outgoingItem.IsMaster && selectedFreemover === true ?
                <td><div className={styles.smallFont}></div></td>
                :
                <td><div className={styles.smallFont}>{selectedUni?.ItemId !== -1 && (selectedUni?.Zusatzinfo ? selectedUni.Zusatzinfo[state.outgoingItem.StudiengangID] : "")}</div></td>
            }
        </tr>
    )
}
export default UniversityTableRow