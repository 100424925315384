import React from 'react';
import {
  FC,
  useState,
  useEffect
} from "react"
import Registration from './components/Registration';
import UploadReport from './components/UploadReport';
import EditKonto from './components/EditKonto';
import { getOAuthToken } from './DataAccess';
import SessionContext from "./entities/SessionContext"
import { Route, Switch } from 'react-router-dom';

const App: FC = () => {
  const [oAuthToken, setoAuthToken] = useState("")
  const [studentGuid, setstudentGuid] = useState("")

  useEffect(() => {
    (async () => {
      const oAuthResponse = await getOAuthToken()

      if (oAuthResponse) {
        setoAuthToken(oAuthResponse.access_token)
      }
    })()

    const accessGuid = getUrlParams().get("access") || "-1";
    setstudentGuid(accessGuid);
  }, []);


  return studentGuid !== "-1" && oAuthToken !== ""
    ? <SessionContext.Provider value={{ oAuthToken, studentGuid }}>
      <Switch>
        <Route exact path="/" component={Registration} />
        <Route path="/report" component={UploadReport} />
        <Route path="/konto" component={EditKonto} />
      </Switch>
    </SessionContext.Provider>
    : null
  /* 
  else {
    return <SessionContext.Provider value={{ oAuthToken, studentGuid }}>
      <Registration />
    </SessionContext.Provider>
  } */
}

const getUrlParams = () => {
  if (!window.location.search) return new URLSearchParams(); else return new URLSearchParams(window.location.search);
}

/* const Child = () => {
  let id = useParams()
  if (id == "report") {
    return <UploadReport />
  }
  else {
    <Registration />
  }
} */

export default App
