import * as React from 'react'
import {
    FC
} from "react"
import styles from "../css/registration.module.scss"
/**
 * Language Self Evaluation Table
 */
const SelfEvaluationTable: FC = () => {

    const small = {
        width: '20%'
    }

    const big = {
        width: '60%'
    }

    return (
        <table id="SprachTable">
            <tbody>
                <tr>
                    <td style={small} rowSpan={2}><div className={styles.smallFont} >Proficient User</div></td>
                    <td style={small}><div className={styles.smallFont}>C2</div></td>
                    <td style={big}><div className={styles.smallFont}>Can understand with ease virtually everything heard or read. Can summarise information from different spoken and written sources, reconstructing arguments and  accounts in a coherent presentation.Can express him/herself spontaneously, very fluently and precisely, differentiating finer shades of meaning even in more complex situations.</div></td>
                </tr>
                <tr>
                    <td style={small}><div className={styles.smallFont}>C1</div></td>
                    <td style={big}><div className={styles.smallFont}>Can understand a wide range of demanding, longer texts,and recognise implicit meaning. Can express him/herself fluently and spontaneously without much obvious searching for expressions. Can use language flexibly and effectively for social, academic and professional purposes. Can produce clear, well-structured, detailed text on complex subjects, showing controlled use of organisational patters, connectors and cohesive devices.</div></td>
                </tr>
                <tr>
                    <td style={small} rowSpan={2}><div className={styles.smallFont}>Independent User</div></td>
                    <td style={small}><div className={styles.smallFont}>B2</div></td>
                    <td style={big}><div className={styles.smallFont}>Can understand the main ideas of complex text on both concrete and abstract topics, including technical discussions in his / her field of specialisation. Can interact with a degree of fluency and spontaneity that makes regular interaction with native speakers quite possible without strain for either party. Can produce  clear, detailed text on a wide range of subjects and explain a viewpoint on a topical issue giving the advantages and disadvantages of various options.</div></td>
                </tr>
                <tr>
                    <td style={small}><div className={styles.smallFont}>B1</div></td>
                    <td style={big}><div className={styles.smallFont}>Can understand the main points of clear standard input on familiar matters regularly encoutered in work, school, leisure, etc. Can deal with most situation likely to arise whilst travelling in an area where the language is spoken.Can produce simple connected texts on topics which are familiar or of personal interest. Can describe experiences and events, dreams, hopes & ambitions and briefly give reasons and explanations for opinions and plans.</div></td>
                </tr>
                <tr>
                    <td style={small} rowSpan={2}><div className={styles.smallFont}>Basic User</div></td>
                    <td style={small}><div className={styles.smallFont}>A2</div></td>
                    <td style={big}><div className={styles.smallFont}>Can understand sentences and frequently used expressions related to areas of most immediate relevance ( e.g. basic personal and family information, shopping, local geography,employment). Can communicate in simple and routine tasks requiring a simple and direct exchange of information on familiar and routine matters. Can describe in simple terms aspects of his/her background, immediate environment and matters in areas of immediate need.</div></td>
                </tr>
                <tr>
                    <td style={small}><div className={styles.smallFont}>A1</div></td>
                    <td style={big}><div className={styles.smallFont}>Can understand and use familiar everyday expressions and very basic phrases aimed at the satisfaction of needs of a concrete type. Can introduce him/ herself and others and can ask and answer questions about personal details such as where he/ she lives, people he/she knows and things he/she has. Can interact in a simple way provided the other person talks slowly and clearly and is prepared to help.</div></td>
                </tr>
            </tbody>
            <caption><small><i>This extract is reproduced in Lingu@net Europe by kind permission of the Council of Europe. Copyright&copy; exclusively of Council of Europe.</i></small></caption>
        </table>
    );

}
export default SelfEvaluationTable