import * as React from "react";

export function handleErrors(response: Response): Response {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

/* export function ListFromString(s: string) {  
    let array = new string[1]();
    array.push(s);
    return List(array);
} */

export function List(array: string[]) {
    const listItems = array.map((text, i) =>
        <li key={i}>
            {text}
        </li>
    );
    return (
        <ul id="ErasmusList">{listItems}</ul>
    );
}
