import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { List } from '../../helper/utilities';

interface ValidationDialogProps {
    errors: string[];
    onClose: () => void;
}

class ValidationDialogState {
    constructor(errors: string[]) {
        this.message = List(errors);
    }
    message: JSX.Element;
}

/**
 * Shows validation errors that are present when attempting to submit the form
 */
export class ValidationDialog extends React.Component<ValidationDialogProps, ValidationDialogState> {
    constructor(props: ValidationDialogProps) {
        super(props);
        this.state = new ValidationDialogState(this.props.errors);
    }



    render() {
        return (
            <Dialog title={"Validation Error"} onClose={this.props.onClose}>
                <p>The following fields are not filled out correctly:</p>
                {this.state.message}
                <DialogActionsBar>
                    <button className="k-button" onClick={this.props.onClose}>OK</button>
                </DialogActionsBar>
            </Dialog>
        );
    }
}