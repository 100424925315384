import * as React from 'react';
import {
    FC,
    useEffect,
    useContext,
    useState
} from "react"
import RadioButton from './inputRadio'
import Textbox from './inputText'
import SessionContext from "../entities/SessionContext"
import { ParentContext } from "./UploadReport"
import { getEvalThemen } from '../DataAccess';
import EvalThemenItem from '../entities/EvalThemenItem';

const Evaluation: FC = () => {
    const { oAuthToken } = useContext(SessionContext)
    const { state, dispatch } = useContext(ParentContext)
    const [themenList, setThemenList] = useState([] as EvalThemenItem[])

    useEffect(() => {
        (async () => {
            const themen = await getEvalThemen(oAuthToken);
            if (themen)
                setThemenList(themen)
        })()
    }, [oAuthToken]);

    const radioChanged = (themenid: number, fragenid: number, value: number) => {
        dispatch({ type: "setAntwortFrage", payload: { ThemenID: themenid, FragenID: fragenid, Auswahl: value, Art: 1, Pflicht: true } })
    }

    return (
        <div>
            {themenList.map((thema) => {
                let selAntwortThema = state.outgoingItem.Antworten.find(f => f.ThemenID === thema.ID && f.Art !== 3);
                let selAntwortKommentar = state.outgoingItem.Antworten.find(f => f.ThemenID === thema.ID && f.Art === 3);
                return <div>
                    <p style={{ marginBottom: "0px" }}><b>{thema.Thema}</b></p>
                    <p style={{ fontSize: "14px", fontStyle: "italic" }}>{thema.Schulnote ? "Please select your answer for each of the following items (compulsory selection). If you cannot give an answer for an item, please select the uncoloured smiley on the far right.:"
                        : thema.Auswahl ? "Please select one answer (compulsory selection):" : ""}</p>
                    {thema.Schulnote === true ?
                        thema.Fragen.map((frage) => {
                            let selAntwortFrage = state.outgoingItem.Antworten.find(f => f.ThemenID === thema.ID && f.FragenID === frage.ID);
                            return <div>
                                <p style={{ marginBottom: "5px" }}>{frage.Bezeichnung}</p>
                                <div className="radioSmileSelector" style={{ marginBottom: "20px" }}>

                                    <input checked={
                                        selAntwortFrage !== undefined
                                        && selAntwortFrage.FragenID !== null
                                        && selAntwortFrage.FragenID === frage.ID && selAntwortFrage.Auswahl === 1
                                    } id={"radioSmile1_" + (frage.ID !== null ? frage.ID : 0)} type="radio" name={"radioSmile_" + (frage.ID !== null ? frage.ID : 0)} value={frage.ID?.toString()}
                                        onChange={(e) => radioChanged(thema.ID, Number(e.target.value), 1)} />
                                    <label className="radioSmile" style={{ backgroundImage: 'url("/img/Smilie_1.png")' }} htmlFor={"radioSmile1_" + (frage.ID !== null ? frage.ID : 0)}></label>
                                    <input checked={
                                        selAntwortFrage !== undefined
                                        && selAntwortFrage.FragenID !== null
                                        && selAntwortFrage.FragenID === frage.ID && selAntwortFrage.Auswahl === 2
                                    } id={"radioSmile2_" + (frage.ID !== null ? frage.ID : 0)} type="radio" name={"radioSmile_" + (frage.ID !== null ? frage.ID : 0)} value={frage.ID?.toString()}
                                        onChange={(e) => radioChanged(thema.ID, Number(e.target.value), 2)} />
                                    <label className="radioSmile" style={{ backgroundImage: 'url("/img/Smilie_2.png")' }} htmlFor={"radioSmile2_" + (frage.ID !== null ? frage.ID : 0)}></label>
                                    <input checked={
                                        selAntwortFrage !== undefined
                                        && selAntwortFrage.FragenID !== null
                                        && selAntwortFrage.FragenID === frage.ID && selAntwortFrage.Auswahl === 3
                                    } id={"radioSmile3_" + (frage.ID !== null ? frage.ID : 0)} type="radio" name={"radioSmile_" + (frage.ID !== null ? frage.ID : 0)} value={frage.ID?.toString()}
                                        onChange={(e) => radioChanged(thema.ID, Number(e.target.value), 3)} />
                                    <label className="radioSmile" style={{ backgroundImage: 'url("/img/Smilie_3.png")' }} htmlFor={"radioSmile3_" + (frage.ID !== null ? frage.ID : 0)}></label>
                                    <input checked={
                                        selAntwortFrage !== undefined
                                        && selAntwortFrage.FragenID !== null
                                        && selAntwortFrage.FragenID === frage.ID && selAntwortFrage.Auswahl === 4
                                    } id={"radioSmile4_" + (frage.ID !== null ? frage.ID : 0)} type="radio" name={"radioSmile_" + (frage.ID !== null ? frage.ID : 0)} value={frage.ID?.toString()}
                                        onChange={(e) => radioChanged(thema.ID, Number(e.target.value), 4)} />
                                    <label className="radioSmile" style={{ backgroundImage: 'url("/img/Smilie_4.png")' }} htmlFor={"radioSmile4_" + (frage.ID !== null ? frage.ID : 0)}></label>
                                    <input checked={
                                        selAntwortFrage !== undefined
                                        && selAntwortFrage.FragenID !== null
                                        && selAntwortFrage.FragenID === frage.ID && selAntwortFrage.Auswahl === 5
                                    } id={"radioSmile5_" + (frage.ID !== null ? frage.ID : 0)} type="radio" name={"radioSmile_" + (frage.ID !== null ? frage.ID : 0)} value={frage.ID?.toString()}
                                        onChange={(e) => radioChanged(thema.ID, Number(e.target.value), 5)} />
                                    <label className="radioSmile" style={{ backgroundImage: 'url("/img/Smilie_5.png")', marginRight: "30px" }} htmlFor={"radioSmile5_" + (frage.ID !== null ? frage.ID : 0)}></label>
                                    <input checked={
                                        selAntwortFrage !== undefined
                                        && selAntwortFrage.FragenID !== null
                                        && selAntwortFrage.FragenID === frage.ID && selAntwortFrage.Auswahl === 0
                                    } id={"radioSmile0_" + (frage.ID !== null ? frage.ID : 0)} type="radio" name={"radioSmile_" + (frage.ID !== null ? frage.ID : 0)} value={frage.ID?.toString()}
                                        onChange={(e) => radioChanged(thema.ID, Number(e.target.value), 0)} />
                                    <label className="radioSmile" style={{ backgroundImage: 'url("/img/Smilie_0.png")' }} htmlFor={"radioSmile0_" + (frage.ID !== null ? frage.ID : 0)}></label>
                                </div>
                            </div>
                        }
                        )
                        : thema.Fragen.map((frage) => {
                            return <p>
                                <RadioButton
                                    id={"Auswahl_" + (frage.ID !== null ? frage.ID : 0)}
                                    name={"Auswahl_" + (thema.ID !== null ? thema.ID : 0)}
                                    value={frage.ID !== null ? frage.ID.toString() : "0"}
                                    label={frage.Bezeichnung}
                                    checked={
                                        selAntwortThema !== undefined
                                        && selAntwortThema.FragenID !== null
                                        && selAntwortThema.FragenID === frage.ID
                                    }
                                    setValue={(newValue: string) => {
                                        dispatch({
                                            type: "setAntwort", payload: {
                                                ThemenID: frage.ThemenID,
                                                Auswahl: null, FragenID: newValue !== "0" ? Number(newValue) : null,
                                                Art: 2,
                                                Pflicht: thema.Pflicht
                                            }
                                        })
                                    }}
                                    setChecked={(newValue: boolean) => { }} />
                            </p>
                        }
                        )
                    }

                    <p>{thema.KommentarPflicht ? "Please justify your answer (compulsory):" : "Further comments & remarks (not compulsory):"}</p>
                    <Textbox
                        name={"Kommentar_" + thema.ID} id={"Kommentar_" + thema.ID}
                        className={undefined} required={false} maxLength={2500}
                        value={selAntwortKommentar !== undefined ? selAntwortKommentar.Kommentar : ""}
                        setText={(newText: string) => { dispatch({ type: "setAntwortKommentar", payload: { ThemenID: thema.ID, Kommentar: newText, Art: 3, Pflicht: thema.KommentarPflicht && newText !== "" } }) }} />
                    <br /><br />
                </div>
            })}

        </div>

    )
}
export default Evaluation