import React, { FC } from 'react'
import { DatePicker } from '@progress/kendo-react-dateinputs';

interface Props {
    id: string,
    name: string,
    value: Date | null,
    setDate: (value: Date) => void,
    required: boolean,
    className: string | undefined
}
const inputDate: FC<Props> = (props) => {
    const dateChanged = (event: any) => {
        props.setDate(event.value)
    }
    return (
        <DatePicker
            name={props.name}
            id={props.id}
            value={!props.value ? new Date() : props.value}
            required={props.required}
            validityStyles={!props.value}
            onChange={dateChanged}
            className={props.className}
            format="dd.MM.yyyy"
        />)
}

export default inputDate