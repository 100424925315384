import React, { FC } from 'react'
import {
    useContext
} from "react"
import LanguageItem from '../../entities/LanguageItem';
import DropDownSelect from '../dropDown';
import { ParentContext } from "../Registration"
import styles from "../css/registration.module.scss"
interface LanguageTableRowProps {
    language: LanguageItem | null
    languages: any[]
    languageLevels: any[]
    index: number
}

const LanguageTableRow: FC<LanguageTableRowProps> = (props) => {
    const { dispatch } = useContext(ParentContext);
    let fileReader: FileReader;
    let strFilename: string;
    let strFiletype: string;

    const handleFileRead = () => {
        let content = "";
        if (typeof fileReader.result === "string") {
            content = fileReader.result.split(",")[1];
        }
        if (content) {
            dispatch({ type: "setChosenLanguagesCerificate", payload: { index: props.index, itemID: props.language?.ItemId ?? 0, certificate: { filename: strFilename, filetype: strFiletype, content: content.toString() } } })
        }
    };

    const fileChanged = (selectorFiles: FileList | null) => {
        if (selectorFiles != null) {
            let file = selectorFiles[0]
            strFilename = file.name
            strFiletype = file.type
            fileReader = new FileReader()
            fileReader.onloadend = handleFileRead
            fileReader.readAsDataURL(file)
        }
    }
    return (
        <tr>
            <td>
                <DropDownSelect data={props.languages} required={false} className={styles.fullwidth} validityStyles={false}
                    valueID={props.language ? props.language.LanguageID < 0 ? "-1" : props.language.LanguageID.toString() : "-1"}
                    valueChanged={(newId: string) => {
                        dispatch({
                            type: "setChosenLanguagesLanguageID",
                            payload: {
                                itemID: props.language?.ItemId ?? 0,
                                index: props.index,
                                languageID: Number(newId)
                            }
                        })
                    }} />
            </td>
            <td>
                {(props.language ? props.language.LanguageID > 0 ? true : false : false) &&
                    <DropDownSelect data={props.languageLevels} required={true} className={styles.fullwidth} validityStyles={false}
                        valueID={props.language?.SelfEvaluation ?? ""}
                        valueChanged={(newId: string) => {
                            dispatch({
                                type: "setChosenLanguagesEvaluation",
                                payload: {
                                    itemID: props.language?.ItemId ?? 0,
                                    index: props.index,
                                    evaluation: newId
                                }
                            })
                        }} />
                }
            </td>
            <td>
                {(props.language ? props.language.LanguageID > 0 ? true : false : false) &&
                    <div className={styles.smallFont}>
                        <input type="file" name="languageCertificate" accept="application/pdf" onChange={(e) => fileChanged(e.target.files)} /><span>
                            <br />{props.language ? `Last uploaded: ${props.language.Certificate?.FileName ?? ""}` : ""}</span>
                    </div>
                }
            </td>
        </tr>
    );
}
export default LanguageTableRow