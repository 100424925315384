import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

interface DsgvoDialogProps {
    onClose: () => void;
    onSubmit: () => void;
}

/**
 * Shown before submit is executed
 */
export class SubmitDialog extends React.Component<DsgvoDialogProps> {
    constructor(props: DsgvoDialogProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Dialog title={"Do you really want to submit?"} closeIcon={false}>
                <p>You can only submit this form once.</p>
                <p>If you want to change any details after submitting, you have to request the reopening of your application.</p>
                <DialogActionsBar>
                    <button className="k-button" onClick={this.props.onClose}>Cancel</button>
                    <button className="k-button" onClick={this.closeAndSubmit}>Submit</button>
                </DialogActionsBar>
            </Dialog>
        );
    }

    private closeAndSubmit = () => {
        this.props.onClose();
        this.props.onSubmit();
    }
}