import React, { FC } from 'react'
import { Input } from '@progress/kendo-react-inputs';
interface Props {
    id: string,
    name: string,
    value: string,
    setText: (value: string) => void,
    required: boolean,
    maxLength: number,
    className: string | undefined
}
const inputText: FC<Props> = (props) => {
    const textChanged = (event: any) => {
        props.setText(event.value)
    }
    return (
        <Input
            name={props.name}
            id={props.id}
            type="text"
            value={props.value}
            required={props.required}
            validityStyles={props.value === ""}
            maxLength={props.maxLength}
            onChange={textChanged}
            className={props.className}
            style={{ width: '100%' }}
        />)
}

export default inputText